import React from "react";
import { Link } from "gatsby";
import Seo from "../components/seo";
import Layout from "../components/layout";
import { cn } from "../lib/helpers";
import Container from "../components/container";

import * as styles from "../styles/components/short-page.module.css";

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Page Not found" />
    <div className={styles.root}>
      <Container>
        <div className="grid">
          <div className="col-12 col-md-6">
            <h1>404</h1>
          </div>
          <div className="col-12 col-md-6">
            <div className="entry-content content-xl">
              <p className="h1">Sorry, we can’t find that page. please try again, or <Link to="/">visit the homepage</Link>.</p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  </Layout>
);

export default NotFoundPage;
